import React,{Component, Fragment} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import './Designer.css';
import Projects from '../../Components/Projects/Projects';
import Papers from '../../Components/Papers/Papers';





class Designer extends Component{


render(){





return(
<Fragment>
{/*<p> Hi im paul!</p>*/}
<Projects/>
</Fragment>
	);


}



}
export default Designer;